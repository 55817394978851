import React, { useEffect, useState, useRef  } from "react";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  RegisterUser,
  auth,
  getUserData,
  getWalletBalance,
  userLogout,
} from "./firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import {useDispatch, useSelector } from "react-redux";
import { FaUserCircle,FaWallet } from "react-icons/fa";
import { saveWalletBalance } from "./redux/userSlice";
import { useNavigate } from "react-router-dom";


const Navbar = () => {
  const [showMobileNumberForm, setShowMobileNumberForm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [verifying, setVerifying] = useState(false);
  const [otpverifying, setotpverifying] = useState(false);

  const [focusedIndex, setFocusedIndex] = useState(0);

  const inputRefs = useRef(Array(6).fill(null).map(() => React.createRef()));


  const {balance} = useSelector(state=>state.user);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleSendOtp = async () => {
    const recaptchaContainer = document.getElementById("recaptcha-container");

    if (auth) {
      try {
        setVerifying(true);
        const recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "normal",
            callback: (response) => {
              // console.log("reCAPTCHA completed:", response);
            },
          }
        );
        const confirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          recaptchaVerifier
        );
        recaptchaContainer.style.display = "none";
        setConfirmationResult(confirmationResult);
        setVerifying(false);
      } catch (error) {
        setVerifying(false);
        recaptchaContainer.style.display = "block";
        // console.error("phoneNumber:", phoneNumber);
        // console.error("Error sending OTP:", error);
        throw error;
      }
    } else {
      // console.log(auth, "auth ");
    }
  };

  const handleCloseModal = () => {
    setShowMobileNumberForm(false);
    const myModal = new window.bootstrap.Modal(document.getElementById("myModal"));
    myModal.hide();
    
    // Explicitly remove the modal backdrop
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  };

  const handleVerifyOtp = async () => {
    try {
      if (confirmationResult) {
        setotpverifying(true);
        const userCredential = await confirmationResult.confirm(
          otpValues.join('')
        );
        // console.log(userCredential, "userCredential");
        const user = userCredential.user;
        if (user?.phoneNumber) {
          RegisterUser(user.phoneNumber, () => {
            setotpverifying(false);
            navigation('/recharge');
            window.location.reload();
          });
        }
        // console.log("User authenticated:", user);
      } else {
        // console.error("Confirmation result is undefined");
      }
    } catch (error) {
      // console.error("Error verifying OTP:", error.message);
    }
  };

  const openModal = () => {
    const myModal = new window.bootstrap.Modal(
      document.getElementById("myModal")
    );
    myModal.show();
  };

  // const handleOtpChange = (event) => {
  //   setOtp(event.target.value);
  // };

  const handleOtpChange = (index, value) => {
    if (/^\d+$/.test(value)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;

      setOtpValues(newOtpValues);

      // Move focus to the next input box
      if (index < 5) {
        setFocusedIndex(index + 1);
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && index > 0) {
      // Move focus to the previous input box on Backspace
      setFocusedIndex(index - 1);
    }
  };

  const handleChange = (index, value) => {
    if (/^\d+$/.test(value)) {
      const newOtpValues = [...otpValues];
      newOtpValues[index] = value;

      setOtpValues(newOtpValues);

      // Move focus to the next input box
      if (index < 5) {
        setFocusedIndex(index + 1);
      }
    }
  };

  // function checknum(event) {
  //   setPhoneNumber(event.target.value);
  // }
  const formatPhoneNumber = (phoneNumber) => {
    var ph =  phoneNumber.replace(/[\s-]/g, '').replace(/[^0-9+]/g, '');
    var nph = "+"+ph;
    // console.log(nph)
    return nph;
  };

  useEffect(() => {
    getUserData();
    getWalletBalance(dispatch,saveWalletBalance);
  }, []);

  useEffect(() => {
    // Focus on the input box when the focused index changes
    inputRefs.current[focusedIndex]?.current?.focus();
  }, [focusedIndex]);



  return (
    <nav className="navbar navbar-expand-lg navbar-white bg-white box_shadow">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <a
            className="navbar-brand"
            href="#"
            id="if_not_login"
            style={{ display: "none" }}
          >
            <img src={process.env.PUBLIC_URL + "/assets/logo.png"} className="img-fluid logo" />
          </a>
          <a
            className="navbar-brand"
            href="recharge"
            id="if_login"
            style={{ display: "none" }}
          >
            <img src={process.env.PUBLIC_URL + "/assets/logo.png"} className="img-fluid logo" />
          </a>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ display: "none" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {/* <li className="nav-item">
              <a className="nav-link" href="/">
                Home
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/login">
                Login
              </a>
            </li> */}
            {/* <li className="nav-item">
              <a className="nav-link" href="/PhoneAuth">
                PhoneAuth
              </a>
            </li> */}
          </ul>
        </div>

        <div
          className="user text-nowrap userf_icn"
          data-bs-toggle="modal"
          data-bs-target="#myModal"
          id="users"
          onClick={openModal}
        >
          
          <div className="_user1_">
            <span className="mx-2 user_name" id="user_place">
              Sign In
            </span>
            <FaUserCircle />
          </div>
        </div>

        <div className="user_login text-nowrap">
          <ul className="list-unstyled profile_icn">
            <li className="nav-item header-nav-item dropdown">
              <div className="dropdown">
                <a href="/recharge" className="nav-link btn btn_green">
                  <div className="_user1_">
                    <span
                      className="mx-2 text-white user_name"
                      id="user_place_login"
                    ></span>
                    <img
                      src={process.env.PUBLIC_URL + "/assets/user.png"}
                      className="ul"
                      style={{ width: "30px" }}
                      alt="user"
                    />
                  </div>
                </a>
                <ul
                  className="dropdown-menu mobile_dropdown"
                  style={{ borderRadius: "5px", padding: "20px" }}
                >
                  <li>
                  <FaWallet /> &nbsp; Wallet Balance:{" "}
                    <span className="wallet_bal">{balance}</span>
                    <hr />
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="/profile"
                    >
                      <h6 className="fs-14">View Profile</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="/orders"
                    >
                      <h6 className="fs-14">Your Orders</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="/nutewallet"
                    >
                      <h6 className="fs-14">Your Wallet</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="/help"
                    >
                      <h6 className="fs-14">24x7 Help</h6>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item d-flex align-items-center justify-content-between"
                      href="#"
                      onClick={()=>userLogout(()=>{navigation('/')})}
                    >
                      <h6 className="fs-14">Sign out</h6>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
        {/* </div> */}

        <div className="modal" id="myModal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title">Login with your Nute account</h6>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModal}
                ></button>
              </div>

              <div className="modal-body">
                {showMobileNumberForm ? (
                  <div>
                    <p className="text-center pb-3">Login to Mobile Number</p>
                    {!confirmationResult ? (
                      <>
                        {/* <input
                          type="text"
                          id="mobile_code"
                          name="uname"
                          placeholder="Phone Number"
                          className="form-control"
                          value={phoneNumber}
                          onChange={checknum}
                          required
                        /> */}

<PhoneInput
        country={'in'}
        className="mx-auto"
        value={phoneNumber}
        onChange={phoneNumber => setPhoneNumber(formatPhoneNumber(phoneNumber))}
      />

                        <div
                          id="recaptcha-container"
                          className="my-3 d-flex justify-content-center"
                        ></div>
                        <button
                          onClick={handleSendOtp}
                          className="btn btn-success verify mt-3 mx-auto d-block px-5"
                          disabled={verifying}
                        >
                          {verifying ? "Processing" : "Verify"}
                          {verifying ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : null}
                        </button>
                      </>
                    ) : (
                      <div className="text-center">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="OTP"
                          value={otp}
                          onChange={handleOtpChange}
                        /> */}

{otpValues.map((value, index) => (
  <input
    key={index}
    type="text"
    value={value}
    onChange={(e) => {
      handleOtpChange(index, e.target.value);
      handleChange(index, e.target.value);
    }}
    onKeyDown={(e) => handleKeyDown(index, e)}
    maxLength={1}
    autoFocus={index === focusedIndex}
    ref={inputRefs.current[index]}
    disabled={index > focusedIndex}
    style={{
      width: '40px', 
      height: '40px', 
      margin: '5px', 
      fontSize: '16px', 
      textAlign: 'center', 
    }}
  />
))}


                        <button
                          onClick={handleVerifyOtp}
                          className="btn btn-success  mt-3 mx-auto d-block px-5"
                          disabled={otpverifying}
                        >
                          {otpverifying ? "Verifying" : "Verify OTP"}
                          {otpverifying ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : null}
                        </button>
                      </div>
                    )}
                    <input type="hidden" id="phonecode" value="+91" />
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="h6 mb-3"> Steps to scan QR Code </div>
                      <ol className="model-body">
                        <li style={{ marginBottom: "5px" }}>
                          <span className="model-body">Open Nute App</span>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <span className="model-body">
                            Tap Scan option available at the bottom
                          </span>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <span className="model-body">
                            Point Nute Scan at QR Code to login
                          </span>
                        </li>
                        <li style={{ marginBottom: "5px" }}>
                          <span>
                            Login to{" "}
                            <a
                              className="txt_green bold"
                              onClick={() => setShowMobileNumberForm(true)}
                              style={{ cursor: "pointer" }}
                            >
                              Mobile Number
                            </a>
                          </span>
                        </li>
                      </ol>
                    </div>
                    <div className="col-12 col-md-6 d-flex justify-content-center">
                      <div className="qr-nute">
                        <a
                          href="https://nute.io"
                          border="0"
                          style={{ cursor: "pointer", display: "block" }}
                        >
                          <img
                            src="https://cdn2.me-qr.com/qr/84434277.png?v=1701433330"
                            alt="QR Code"
                            className="img-fluid mx-auto d-block "
                          />
                        </a>
                        <a
                          href="https://nute.io/"
                          border="0"
                          style={{ cursor: "default", display: "none" }}
                        >
                          Nute
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-4 ps-4 mb-4">
                <span className="model-body1">
                  By signing in, you agree to our{" "}
                  <a
                    href="https://nute.io/privacy_policy"
                    className="txt_green bold"
                  >
                    privacy policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://nute.io/terms_of_use"
                    className="txt_green bold"
                  >
                    terms of use{" "}
                  </a>
                </span>
              </div>
              <div className="modal-footer">
                <div className="row">
                  <div className="col-12 col-md-6 d-flex justify-content-center modal-footer1">
                    <span>To create an account download Nute App</span>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-center">
                    <img
                      src="https://nute.io/public/uploads/brandimg1.png"
                      className="model-img img-fluid"
                    />
                    <img
                      src="https://nute.io/public/uploads/brandimg2.png"
                      className="model-img img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
