import React, {  useEffect, useState } from 'react';
import App_header from "./App_header";
import PropTypes from 'prop-types';
import Select from 'react-select';

const DTH = () => {
  const [selectedOption, setSelectedOption] = useState({ value: 'DTH Operator', label: 'DTH Operator' });
  const [mobileNumber, setMobileNumber] = useState('');
  const [amount, setAmount] = useState(null);
  const [operatorID, setOperatorID] = useState(null);
  const [responseData, setResponseData] = useState(null);

  const [keys, setKeys] = useState([]);
  const [activePlan, setActivePlan] = useState('Plan');

  const [operatorName, setOperatorName] = useState('');

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [amountError, setAmountError] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setOperatorName(selectedOption.label || '');
  }, [selectedOption]);

  const handleNumberChange = (e) => {
    const enteredAmount = e.target.value;
    setMobileNumber(enteredAmount);
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: '30px', margin: '15px' }}
      />
      {label}
    </div>
  );

  CustomOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    data: PropTypes.shape({
      image: PropTypes.string,
    }),
  };

  const handleChange = (selectedValue) => {
    setSelectedOption(selectedValue);
    const selectedOperator = options.find(operator => operator.value === selectedValue.value);
    setOperatorID(selectedOperator ? selectedOperator.id : null);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  
    let validAmount = false;
    let newSelectedPlan = null;
  
    responseData[activePlan].forEach((pl) => {
      if (pl.rs[Object.keys(pl.rs)[0]] === e.target.value) {
        newSelectedPlan = pl;
        validAmount = true;
      }
    });
  
    setSelectedPlan(newSelectedPlan);
    setAmountError(!validAmount);
  };
  
  
  


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await fetch('https://nute.io/api/dth/biller_list', {
          method: 'GET',
          headers: {
            'Cookie': 'ci_session=8o2rklmmeb88dqkrdg0vveova552lkbn'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const options = data && data.response
    .filter(operator => operator.service_type === 'DTH')
    .map(operator => ({
      value: operator.operator_name,
      label: operator.operator_name,
      id: operator.operator_id,
      image: operator.operator_name === 'AIRTEL DTH' ? 'assets/icon/airtel.webp'
        : operator.operator_name === 'Airtel DTH Official' ? 'assets/icon/airtel.webp'
        : operator.operator_name === 'BIG TV DTH' ? 'assets/icon/big_tv.png'
        : operator.operator_name === 'DISH DTH' ? 'assets/icon/d2h.webp'
        : operator.operator_name === 'SUN DTH' ? 'assets/icon/sun_direct.webp'
        : operator.operator_name === 'Sun Direct Official' ? 'assets/icon/sun_direct.webp'
        : operator.operator_name === 'TATA SKY DTH' ? 'assets/icon/tata_play.webp'
        : operator.operator_name === 'Tata Sky Official' ? 'assets/icon/tata_play.webp'
        : operator.operator_name === 'Dish TV Official' ? 'assets/icon/dishtv.avif'
        : operator.operator_name === 'VIDEOCON DTH' ? 'assets/icon/videocon.png'
        : operator.operator_name === 'Videocon D2h Official' ? 'assets/icon/videocon.png'
        : 'assets/icon/default.png',
    }));

  const defaultOptions = [{ value: 'Default', label: 'Default', image: 'assets/icon/default.png' }];

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (operatorID !== null) {
          setLoading(true);

          const response = await fetch(`https://nute.io/app_api/view_plan/${operatorID}`, {
            method: 'GET',
            headers: {
              'Cookie': 'ci_session=tj9mqmj0m6cq0fune8t9ecfqfogbco86',
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();

          let keys = Object.keys(data.plans);
          keys = keys.filter(key => data.plans[key] !== null);
          setKeys(keys);
          setResponseData(data.plans);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [operatorID]);

  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 box_new'>
            <div className='card light_box mb-5'>
              <div className='card-body'>
                <h6 className="mb-3">Recharge DTH or TV</h6>
                <form>
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={options || defaultOptions}
                    components={{ Option: CustomOption }}
                    className='mt-4 drp_scroll'
                  />
                  <div className="form-floating position-relative">
                    <input
                      type="text"
                      id="prepaidNumber"
                      value={mobileNumber}
                      onChange={handleNumberChange}
                      className="form-control floating_input"
                      placeholder="Mobile Number"
                      onKeyDown={(event) =>
                        (event.ctrlKey ||
                          event.altKey ||
                          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                          (95 < event.keyCode && event.keyCode < 106) ||
                          event.keyCode === 8 ||
                          event.keyCode === 9 ||
                          (event.keyCode > 34 && event.keyCode < 40) ||
                          event.keyCode === 46)
                          ? true
                          : event.preventDefault()
                      }
                    />
                    <label htmlFor="prepaidNumber">Registered Mobile No./Customer ID</label>
                  </div>
                  <div className="form-floating ">
                    <input
                      type="text"
                      className={`form-control floating_input ${amountError ? 'is-invalid' : ''}`}
                      id="prepaidAmount"
                      placeholder="Amount"
                      value={amount}
                      onChange={handleAmountChange}
                      onKeyDown={(event) =>
                        (event.ctrlKey ||
                          event.altKey ||
                          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                          (95 < event.keyCode && event.keyCode < 106) ||
                          event.keyCode === 8 ||
                          event.keyCode === 9 ||
                          (event.keyCode > 34 && event.keyCode < 40) ||
                          event.keyCode === 46)
                          ? true
                          : event.preventDefault()
                      }
                    />
                    <label htmlFor="prepaidAmount">Amount</label>
                    {amountError && (
                      <div className="invalid-feedback">Invalid amount. Please choose a valid plan.</div>
                    )}
                  </div>
                  {!amountError && selectedPlan && (
  <div className="selected-plan-details">
    <p className='mb-0'>Plan Name: {selectedPlan.plan_name}</p>
    <p className='mb-0'>Description: {selectedPlan.desc}</p>
    <p className='mb-0'>Validity: {Object.keys(selectedPlan.rs)[0]}</p>
  </div>
)}

                  <button type="submit" disabled={!mobileNumber || !amount} className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed to Recharge</button>
                </form>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-8 box_new mobile_box'>
            <div className='card light_box mb-5'>
              <div className="card-header pb-0" style={{ background: 'transparent' }}>
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item hov" role="presentation">
                    <a
                      className="nav-link active card_heading"
                      id="tab_wallet-tab"
                      data-bs-toggle="pill"
                      href="#tab_wallet"
                      aria-selected="true"
                      role="tab"
                    >
                      <p className="ps-3">
                        Browse Plan <span style={{ color: '#005e31', fontWeight: '600' }} id="current_opt">{operatorName}</span>
                      </p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tbl">
                  <div className="operator_plans">
                    <div>
                      <div className="plan_header" id="plans-cont">
                        {keys &&
                          keys.map((element, i) => (
                            <span
                              style={{
                                cursor: "pointer",
                                padding: "4px 10px",
                                backgroundColor:
                                  activePlan === element ? "#005e31" : "transparent",
                                color: activePlan === element ? "#fff" : "#fff",
                                borderRadius: activePlan === element ? "4px" : "",
                              }}
                              key={i}
                              onClick={() => setActivePlan(element)}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                    <table className="table">
                      <thead style={{ fontSize: '13px' }}>
                        <tr>
                          <th width="200" style={{ color: '#005e31' }}>Plan Name</th>
                          <th style={{ color: '#005e31' }}>Description</th>
                          <th width="100" style={{ color: '#005e31' }}>Validity</th>
                          <th width="150" style={{ color: '#005e31' }}>Price</th>
                        </tr>
                      </thead>
                    </table>
                    <div id="plans-list" className="plans_list">
                      {loading ? (
                        <div className='text-center pt-5'>
                          <i className="fa fa-spinner fa-spin fa-5x"></i>
                        </div>
                      ) : (
                        responseData ? (
                          responseData[activePlan] && responseData[activePlan].map((plan, i) => {
                            if (plan.rs && plan.rs[Object.keys(plan.rs)[0]] > 0) {
                              return (
                                <table
                                  key={i}
                                  className={`table table-hover`}
                                  onClick={() => {
                                    setAmount(plan.rs && plan.rs[Object.keys(plan.rs)[0]]);
                                    setSelectedPlan(plan);
                                    // setAmountError(false);
                                  }}
                                >
                                  <tbody style={{ cursor: "pointer", fontSize: "14px", color: "#818181" }}>
                                    <tr>
                                      <td width="200" className={`${plan.rs && plan.rs[Object.keys(plan.rs)[0]] === amount ? 'bg_dark_green' : ''}`}>
                                        {plan.plan_name}
                                      </td>
                                      <td className={`${plan.rs && plan.rs[Object.keys(plan.rs)[0]] === amount ? 'bg_dark_green' : ''}`}>
                                        {plan.desc}
                                      </td>
                                      <td width="100" className={`${plan.rs && plan.rs[Object.keys(plan.rs)[0]] === amount ? 'bg_dark_green' : ''}`}>
                                        {Object.keys(plan.rs)[0]}
                                      </td>
                                      <td width="150" className={`${plan.rs && plan.rs[Object.keys(plan.rs)[0]] === amount ? 'bg_dark_green' : ''}`}>
                                        <button className='btn btn-outline-success px-4 py-1'>
                                          ₹ {plan.rs && plan.rs[Object.keys(plan.rs)[0]]}
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              );
                            }
                          })
                        ) : (
                          <div className='text-center pt-5'>
                            <i className="fa fa-spinner fa-spin fa-5x"></i>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DTH;
