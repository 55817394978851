import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  balance: '',
  phoneNumber:'',
};

const userSlice = createSlice({
  name: "userslice",
  initialState,
  reducers: {
    saveWalletBalance: (state, action) => {
      state.balance =  action.payload.balance ;
    },
    saveUserPhoneNumber: (state, action) => {
        state.phoneNumber =  action.payload.phoneNumber ;
      },
  },
});

export const { saveWalletBalance,saveUserPhoneNumber } = userSlice.actions;
export default userSlice.reducer;
