import React, { useState, useEffect } from 'react';
import App_header from "./App_header";
import { useSelector } from "react-redux";

const Recharge = () => {
  const { phoneNumber } = useSelector((state) => state.user);
  const [selectedValue, setSelectedValue] = useState('Prepaid');
  const [showPrepaid, setshowPrepaid] = useState(selectedValue === 'Prepaid');
  const [showPostpaid, setShowPostpaid] = useState(selectedValue === 'Postpaid');
  const [mobileNumber, setMobileNumber] = useState('');
  const [defaultOperator, setDefaultOperator] = useState('');
  const [defaultCircle, setDefaultCircle] = useState('');
  const [keys, setTopKey] = useState([]);
  const [activePlan, setActivePlan] = useState('FULLTT');
  const [amount, setAmount] = useState(null);
  const [billAmount, setbillAmount] = useState(null);
  const [loadingOperator, setLoadingOperator] = useState(false);
  const [operatorID, setOperatorID] = useState(null);
  const [updatingMobileNumber, setUpdatingMobileNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  var user_mobile_num =
    typeof phoneNumber == "object"
      ? phoneNumber?.phoneNumber
      : phoneNumber;
  const newMobileNum = user_mobile_num.substring(3);

  const setOperatorNameBasedOnValue = (operatorNameVal) => {
    if (operatorNameVal === "Reliance Jio Infocomm Limited") {
      setOperatorID(8);
    } else if (operatorNameVal === "AIRTEL") {
      setOperatorID(1);
    } else if (operatorNameVal === "VODAFONE") {
      setOperatorID(3);
    } else if (operatorNameVal === "BSNL GSM") {
      setOperatorID(4);
    }
  };

  useEffect(() => {
    setOperatorNameBasedOnValue(defaultOperator);
  }, [defaultOperator]);

  useEffect(() => {
    if (mobileNumber.length >= 10) {
      listenNumber();
    }
  }, [mobileNumber]);

  const listenNumber = () => {
    setLoadingOperator(true);
    setUpdatingMobileNumber(true);
    setLoading(true);
    fetch(`https://nute.io/app_api/recharge?number=${mobileNumber}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setDefaultOperator(data.operator);
        setDefaultCircle(data.circle);
        viewPlan();
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        console.log(loadingOperator);
        setLoadingOperator(false);
        setUpdatingMobileNumber(false);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

    setshowPrepaid(newValue === 'Prepaid');
    setShowPostpaid(newValue === 'Postpaid');
  };

  const handleNumberChange = (e) => {
    setMobileNumber(e.target.value);
  };

  useEffect(() => {
    setMobileNumber(newMobileNum);
  }, [newMobileNum]);

  const viewPlan = () => {
  };

  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (operatorID !== null) {
          setLoading(true);
          const response = await fetch(`https://nute.io/app_api/view_plan/${operatorID}`, {
            method: 'GET',
            headers: {
              'Cookie': 'ci_session=tj9mqmj0m6cq0fune8t9ecfqfogbco86',
            },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const data = await response.json();
          let keys = Object.keys(data.plans);
          keys = keys.filter(key => data.plans[key] !== null);
          setTopKey(keys);
          setResponseData(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [operatorID]);

  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
<div className='row'>
      <div className='col-12 col-md-4 box_new'>
      <div className='card light_box mb-5'>
      <div className='card-body'>
      {showPrepaid && <h6 className="mb-3">Mobile Recharge Prepaid</h6>}
      {showPostpaid && <h6 className="mb-3">Mobile Recharge Postpaid</h6>}
      <form>
      <label className='me-3'>
        <input 
        className='me-1'
          type="radio" 
          value="Prepaid" 
          checked={selectedValue === 'Prepaid'} 
          onChange={handleChange} 
        />
        Prepaid
      </label>
      <label>
        <input 
        className='me-1'
          type="radio" 
          value="Postpaid" 
          checked={selectedValue === 'Postpaid'} 
          onChange={handleChange} 
        />
        Postpaid
      </label>

      {showPrepaid && (
        <div>
      <div className="form-floating position-relative">
                        <input
                          type="text"
                          id="prepaidNumber"
                          value={mobileNumber}
                          onChange={handleNumberChange}
                          className="form-control floating_input"
                          placeholder="Mobile Number"
                          onKeyDown={(event) =>
                            (event.ctrlKey ||
                              event.altKey ||
                              (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                              (95 < event.keyCode && event.keyCode < 106) ||
                              event.keyCode === 8 ||
                              event.keyCode === 9 ||
                              (event.keyCode > 34 && event.keyCode < 40) ||
                              event.keyCode === 46)
                              ? true
                              : event.preventDefault()
                          }
                        />
                        {updatingMobileNumber && (
                          <div className="loading-indicator operator-circle-spinner">
                            <i className="fa fa-spinner fa-spin"></i>
                          </div>
                        )}
                      </div>
                      <div className="form-floating position-relative">
                        <input
                          type="text"
                          className="form-control floating_input bg-white"
                          id="prepaidOperator"
                          value={updatingMobileNumber ? '' : defaultOperator}
                          readOnly
                          placeholder="Operator"
                        />
                        <label htmlFor="prepaidOperator">Operator</label>
                      </div>
                      <div className="form-floating position-relative">
                        <input
                          type="text"
                          className="form-control floating_input bg-white"
                          id="prepaidCircle"
                          value={updatingMobileNumber ? '' : defaultCircle}
                          readOnly
                          placeholder="Circle"
                        />
                        <label htmlFor="prepaidCircle">Circle</label>
                      </div>
  <div className="form-floating">
    <input
      type="text"
      className="form-control floating_input"
      id="prepaidAmount"  
      placeholder="Amount"
      value={amount}
      onChange={(e)=>setAmount(e.target.value)}
      onKeyDown={(event) =>
        (event.ctrlKey ||
          event.altKey ||
          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
          (95 < event.keyCode && event.keyCode < 106) ||
          event.keyCode === 8 ||
          event.keyCode === 9 ||
          (event.keyCode > 34 && event.keyCode < 40) ||
          event.keyCode === 46)
          ? true
          : event.preventDefault()
      }
    />
    <label htmlFor="prepaidAmount">Amount</label>
  </div>
  <button type="submit" disabled={!mobileNumber || !amount} className="btn btn_green text-white mt-3 mx-auto process d-block px-3">
    Proceed to Recharge
  </button>
  </div>
)}

{showPostpaid && (
  <div>
  <div className="form-floating">
    <input
      type="text"
      id="postpaidNumber"  
      value={mobileNumber}
      onChange={handleNumberChange}
      className="form-control floating_input"
      placeholder="Mobile Number"
      onKeyDown={(event) =>
        (event.ctrlKey ||
          event.altKey ||
          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
          (95 < event.keyCode && event.keyCode < 106) ||
          event.keyCode === 8 ||
          event.keyCode === 9 ||
          (event.keyCode > 34 && event.keyCode < 40) ||
          event.keyCode === 46)
          ? true
          : event.preventDefault()
      }
    />
    <label htmlFor="postpaidNumber">Postpaid Number</label>
  </div>
  <div className="form-floating">
    <input
      type="text"
      className="form-control floating_input bg-white"
      id="postpaidOperator"  
      value={defaultOperator}
      readOnly
      placeholder="Operator"
    />
    <label htmlFor="postpaidOperator">Operator</label>
  </div>
  <div className="form-floating">
    <input
      type="text"
      className="form-control floating_input bg-white"
      id="postpaidCircle"  
      value={defaultCircle}
      readOnly
      placeholder="Circle"
    />
    <label htmlFor="postpaidCircle">Circle</label>
  </div>
  <div className="form-floating">
  <input
      type="text"
      className="form-control floating_input"
      id="postpaidAmount"  
      placeholder="Bill Amount"
      value={billAmount}
      onChange={(e)=>setbillAmount(e.target.value)}
      onKeyDown={(event) =>
        (event.ctrlKey ||
          event.altKey ||
          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
          (95 < event.keyCode && event.keyCode < 106) ||
          event.keyCode === 8 ||
          event.keyCode === 9 ||
          (event.keyCode > 34 && event.keyCode < 40) ||
          event.keyCode === 46)
          ? true
          : event.preventDefault()
      }
    />
    <label htmlFor="postpaidAmount">Bill Amount</label>
  </div>
  <button type="submit" disabled={!mobileNumber || !billAmount} className="btn btn_green text-white mt-3 mx-auto process d-block px-3">
    Proceed to Pay Bill
  </button>
  </div>
)}

      
    </form>

      </div>
      
      </div>

      </div>

      {selectedValue === 'Prepaid' && (
      <div className='col-12 col-md-8 box_new mobile_box'>
      <div className='card light_box mb-5'>
      <div className="card-header pb-0" style={{ background: 'transparent' }}>
      <ul className="nav nav-pills" role="tablist">
        <li className="nav-item hov" role="presentation">
          <a 
            className="nav-link active card_heading" 
            id="tab_wallet-tab" 
            data-bs-toggle="pill" 
            href="#tab_wallet" 
            aria-selected="true" 
            role="tab"
          >
            <p className="ps-3">
              Browse Plan <span style={{ color: '#005e31',fontWeight:'600' }} id="current_opt">{defaultOperator}</span>
            </p>
          </a>
        </li>
      </ul>
    </div>

    <div className="card-body">
      <div className="tbl">
        <div className="operator_plans">
          <div>
          <div className="plan_header" id="plans-cont">
  {keys &&
    keys.map((element, i) => {
      return (
        <span
          style={{
            cursor: "pointer",
            padding: "4px 10px",
            backgroundColor:
              activePlan === element ? "#005e31" : "transparent",
            color: activePlan === element ? "#fff" : "#fff",
            borderRadius: activePlan === element ? "4px" : "",
          }}
          key={i}
          onClick={() => setActivePlan(element)}
        >
          {element}
        </span>
      );
    })}
</div>

          </div>
          <table className="table">
            <thead style={{ fontSize: '13px'}}>
              <tr>
                <th width="100" style={{color: '#005e31'}}>Type</th>
                <th width="100" style={{color: '#005e31'}}>Validity</th>
                <th style={{color: '#005e31'}}>Description</th>
                <th width="150" style={{color: '#005e31'}}>Price</th>
              </tr>
            </thead>
          </table>
          <div id="plans-list" className="plans_list">
          
          {loading ? (
  <div className='text-center pt-5'>
    <i className="fa fa-spinner fa-spin fa-5x"></i>
  </div>
) : (responseData ? (
  responseData.plans[activePlan].map((card, i) => (
    <table
      key={i}
      className={`table table-hover`}
      onClick={() => setAmount(card.rs)}
    >
      <tbody style={{ cursor: "pointer", fontSize: "14px", color: "#818181" }}>
        <tr>
          <td width="100" className={`${card.rs === amount ? 'bg_dark_green' : ''}`}>{card.Type}</td>
          <td width="100" className={`${card.rs === amount ? 'bg_dark_green' : ''}`}>{card.validity}</td>
          <td className={`${card.rs === amount ? 'bg_dark_green' : ''}`}>{card.desc}</td>
          <td width="150" className={`${card.rs === amount ? 'bg_dark_green' : ''}`}>
            <button className='btn btn-outline-success px-4 py-1'>₹ {card.rs}</button>
          </td>
        </tr>
      </tbody>
    </table>
  ))
) : (
  <div className='text-center pt-5'>
    <i className="fa fa-spinner fa-spin fa-5x"></i>
  </div>
))}


          </div>
        </div>
      </div>
    </div>

      </div>
      </div>
      )}
      {selectedValue === 'Postpaid' && (
      <div className='col-12 col-md-8 box_new mobile_box'>
      <div className='card light_box mb-5'>
      <div className="card-header pb-0" style={{ background: 'transparent' }}>
      <ul className="nav nav-pills" role="tablist">
        <li className="nav-item hov" role="presentation">
          <a 
            className="nav-link active card_heading" 
            id="tab_wallet-tab" 
            data-bs-toggle="pill" 
            href="#tab_wallet" 
            aria-selected="true" 
            role="tab"
          >
            <p className="ps-3">Promo Code</p>
          </a>
        </li>
      </ul>
    </div>

    <div className="card-body">
      <div className="tbl">
        <div className="operator_plans">
          <div>
            <div className="plan_header">
            </div>
          </div>
          
          <div className="plans_list">
          </div>
        </div>
      </div>
    </div>

      </div>
      </div>
      )}
      </div>
      </div>
      
      </div>
  )
}
export default Recharge