import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  addDoc,
  setDoc ,
} from "firebase/firestore";
import { saveUserPhoneNumber } from "./redux/userSlice";

const firebaseConfig = {
  apiKey: "AIzaSyCOS8s8-PPl0ggbe1fyJqSqIN2TRcQJaCw",
  authDomain: "nuteapp-672cc.firebaseapp.com",
  projectId: "nuteapp-672cc",
  storageBucket: "nuteapp-672cc.appspot.com",
  messagingSenderId: "228049925155",
  appId: "1:228049925155:web:8002767a086c4f3dc42d96",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

function RegisterUser(number, cb) {
  const collectionName = "users";

  if (number) {
    const userDoc = doc(collection(db, collectionName), number);

    getDoc(userDoc)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          localStorage.setItem("userData", JSON.stringify(data));
        } else {
          localStorage.setItem(
            "userData",
            JSON.stringify({ phoneNumber: number, aadharKyc: 0 })
          );
        }
        cb();
      })
      .catch((error) => {
        // console.error("Error getting document: ", error);
        cb();
        // window.location.href = "https://nute.io";
      });
  }
}

async function fetchUserDetails(...options) {
  var result = await localStorage.getItem("userData");

  if (result) {
    var fres = JSON.parse(result);
    var option_data = {};
    if (options.length > 0) {
      options.map((it, i) => (option_data[it] = fres[it]));
      return option_data;
    } else {
      return fres;
    }
  } else {
    return null;
  }
}

async function getWalletBalance(dispatch, saveWalletBalance) {
  var userdoc = await fetchUserDetails();
  var user_mobile = userdoc?.phoneNumber ? userdoc["phoneNumber"] : "";
  var user_fav_currency;

  user_fav_currency = await favouriteCurrencies(user_mobile);
  var balance = user_fav_currency?.walletBalance
    ? user_fav_currency["walletBalance"] + " ₹"
    : "0.0 ₹";
  var num = await fetchUserDetails("phoneNumber");
  dispatch(saveWalletBalance({ balance: balance }));
  dispatch(saveUserPhoneNumber({ phoneNumber: num }));
}

async function favouriteCurrencies(phoneNumber) {
  try {
    // console.log(phoneNumber,'phonenumber');
    var collectionName = "favouriteCurrencies";
    const favCurrencyDoc = doc(collection(db, collectionName), phoneNumber);

    var docres = await getDoc(favCurrencyDoc);

    if (docres.exists) {
      var data = docres.data();
      return data;
    } else {
      return {};
    }
  } catch (error) {
    // console.log(error, "error");
    return {};
  }
}

async function taxationdata(num) {
  try {
    var collectionName = "taxation";
    const taxationDoc = doc(collection(db, collectionName), num);

    var docres2 = await getDoc(taxationDoc);

    if (docres2.exists) {
      var datatx = docres2.data();
      return datatx;
    } else {
      return {};
    }
  } catch (error) {
    // console.log(error, "error");
    return {};
  }
}

async function getUserData() {
  var userd = await fetchUserDetails();
  //  console.log(userd,' user data in header');
  const user = document.getElementById("users");
  const user_login = document.getElementsByClassName("user_login");
  const user_login_place = document.getElementById("user_place_login");
  // const logocont = document.getElementsByClassName("_user1_");
  const if_login = document.getElementById("if_login");
  const if_not_login = document.getElementById("if_not_login");

  var imageElement = document.getElementsByClassName("ul");
  if (userd) {
    if_login.style.display = "block";
    if_not_login.style.display = "none";
    if (userd.aadharKyc == 1) {
      user_login_place.innerText = "Hi! " + userd.fullName;
      user.style.display = "none";
      for (var i = 0; i < imageElement.length; i++) {
        imageElement[i].src = "data:image/png;base64," + userd.profile_image;
        imageElement[i].style.height = "40px";
        imageElement[i].style.width = "40px";
        imageElement[i].style.borderRadius = "50%";
      }
    } else {
      user_login_place.innerText = "Hi! " + userd.phoneNumber;
      user.style.display = "none";
    }
  } else {
    if_login.style.display = "none";
    if_not_login.style.display = "block";
    user_login[0].style.display = "none";
  }
}

async function saveOrderDetails(data) {
  try {
    const collectionName = "transactions";
    const tnxsDoc = collection(db, collectionName);
    await addDoc(tnxsDoc, data);
    // console.log(res, "res of datasave");
  } catch (e) {
    console.log(e, "error of datasave");
  }
}

async function update_wallet_balance(num, amount) {
  try {
    const collectionName = "favouriteCurrencies";
    var user_fav_currency = await favouriteCurrencies(num);
    var balance = user_fav_currency?.walletBalance
    let final_bal = Number(balance)+Number(amount);
    const taxationDoc = doc(db, collectionName, num);
    await setDoc (taxationDoc, { walletBalance: final_bal }, { merge: true });
    console.log("Data updated successfully");
  } catch (e) {
    console.error("Error updating data:", e);
  }
}

function userLogout(cb) {
  localStorage.removeItem("userData");
  cb();
  window.location.reload();

  // window.location.href = "http://localhost:3000";
}

export {
  auth,
  app,
  RegisterUser,
  fetchUserDetails,
  favouriteCurrencies,
  userLogout,
  getUserData,
  getWalletBalance,
  taxationdata,
  saveOrderDetails,
  update_wallet_balance,
};
