import React from 'react';

const App_header = () => {
 
  return (
    <div className='row'>
      <div className="col-12 bg_light">
        <p className="text-center py-2">
          No Wallet KYC Required to pay using UPI on Nute. Learn more.
        </p>
      </div>
      <div className="col-12 banner_box py-4">

    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav_resp">
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" aria-current="page" href="/recharge">
            <img src="assets/icon/mobile_recharge.png" className="w_50 mb-1" /><br></br>
        Prepaid/Postpaid
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/electricity_bill_payment">
            <img src="assets/icon/electricity.png" className="w_50 mb-1" alt="Electricity" /><br></br>
                  Electricity
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/dth_recharge">
            <img src="assets/icon/DTH.png" className="w_50 mb-1" alt="DTH" /><br></br>
                  DTH
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/metro_card_recharge">
            <img src="assets/icon/METRO.png" className="w_50 mb-1" alt="Metro" /><br></br>
                  Metro
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/loan_emi_payment">
            <img src="assets/icon/loan_payemnt.png" className="w_50 mb-1" alt="Pay Loan" /><br></br>
                  Pay Loan
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/fastag">
            <img src="assets/icon/FASTAG.png" className="w_50 mb-1" alt="Fastag" /><br></br>
                  Fastag
            </a>
          </li>
          <li className="nav-item li_width">
            <a className="nav-link app_nav_hov text-white" href="/education">
            <img src="assets/icon/landline.png" className="w_50 mb-1" /><br></br>
      Education
            </a>
          </li>

{/* dropdown hide on mobile start*/}
          <li className="nav-item dropdown hide_on_mobile">
            <a
              className="nav-link dropdown-toggle text-white"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ><img src="assets/icon/more.png" className="w_50 mb-1"/><br></br>
              More
            </a>
            <ul className="dropdown-menu cst_sub_menu" aria-labelledby="navbarDropdown" style={{left:'-150px'}}>
              
              <li>
                <a className="dropdown-item app_nav_hov" href="/broadband_landline">
                <img src="assets/icon/cng_gas_dark.png" className="w_50 mb-1" />Broadband/Landline
                </a>
              </li>

              <li>
                <a className="dropdown-item app_nav_hov" href="/pay_lic_insurance">
                <img src="assets/icon/LIC_dark.png" className="w_50 mb-1" alt="LIC/Insurance" /> LIC/Insurance
                </a>
              </li>
              
              <li>
                <a className="dropdown-item app_nav_hov" href="/water_bill_payment">
                <img src="assets/icon/water_dark.png" className="w_50 mb-1" alt="Water" /> Water
                </a>
              </li>
            </ul>
          </li>
{/* dropdown hide on mobile close*/}

{/* dropdown items display on mobile start*/}
<li className="nav-item li_width display_on_mobile">
            <a className="nav-link app_nav_hov text-white" href="/broadband_landline">
            <img src="assets/icon/cng_gas.png" className="w_50 mb-1" /><br></br>Broadband/Landline
            </a>
          </li>
          <li className="nav-item li_width display_on_mobile">
            <a className="nav-link app_nav_hov text-white" href="/pay_lic_insurance">
            <img src="assets/icon/LIC.png" className="w_50 mb-1" alt="LIC/Insurance" /> <br></br>LIC/Insurance
            </a>
          </li>
          <li className="nav-item li_width display_on_mobile">
            <a className="nav-link app_nav_hov text-white" href="/water_bill_payment">
            <img src="assets/icon/water.png" className="w_50 mb-1" alt="Water" /><br></br> Water
            </a>
          </li>
{/* dropdown items display on mobile close*/}
        </ul>
      </div>
    </nav>

      </div>
      </div>
  )
}

export default App_header