import React from "react";

function Home() {
  return (
    <section className="bg_light py-5">
      <div className="container py-4 bg-white rounded box_shadow">
        <div className="row py-5 align-items-center justify-content-center">
          <div className="col-12 col-md-6 px-5">
            <h1 className="mb-4 head_mob">The Fastest, Safest, & Easiest Way to Pay</h1>
            <p className="text-secondary mb-3">
              Nute Pay Your one-stop solution for bill payments, recharges,
              bookings, and more. Manage it all effortlessly on our platform.
            </p>
          </div>
          <div className="col-12 col-md-6">
            {/* <img src="assets/Bannerimg.png" className="img-fluid"/> */}
            <dotlottie-player
  src="assets/login.json"
  background="transparent"
  speed="1"
  style={{ width: "80%", margin: "auto" }}
  loop
  autoplay
></dotlottie-player>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
