import React, { useState } from 'react';
import App_header from "./App_header";
import PropTypes from 'prop-types'; // Import prop-types
import Select from 'react-select';

const Loan_emi_payment = () => {

  const [selectedOption, setSelectedOption] = useState({ value: 'Lender', label: 'Lender' });

  const options = [
    { value: 'TVS Credit Services Limited', label: 'TVS Credit Services Limited', image: 'assets/icon/tvs.webp' },
    { value: 'Bajaj Finance Limited', label: 'Bajaj Finance Limited', image: 'assets/icon/bajaj.webp' },
    { value: 'HDB Financial Services', label: 'HDB Financial Services', image: 'assets/icon/hdb.webp' },
    // Add more options as needed
  ];

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: '20px', margin: '8px' }}
      />
      {label}
    </div>
  );

  CustomOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    data: PropTypes.shape({
      image: PropTypes.string,
    }),
  };

  const handleChange = (selectedValue) => {
    setSelectedOption(selectedValue);
  };

  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
<div className='row'>
      <div className='col-12 col-md-4 box_new'>
      <div className='card light_box mb-5'>
      <div className='card-body'>
      <h6 className="mb-3">Pay Loan EMI</h6>

      <form>

      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        components={{ Option: CustomOption }}
      />
        

      
        
        <button type="submit" className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed to Recharge</button>
        
      
    </form>

      </div>
      
      </div>

      </div>

      <div className='col-12 col-md-8 box_new mobile_box'>
      <div className='card light_box mb-5'>
      <div className="card-header pb-0" style={{ background: 'transparent' }}>
      <ul className="nav nav-pills" role="tablist">
        <li className="nav-item hov" role="presentation">
          <a 
            className="nav-link active card_heading" 
            id="tab_wallet-tab" 
            data-bs-toggle="pill" 
            href="#tab_wallet" 
            aria-selected="true" 
            role="tab"
          >
            <p className="ps-3">
            Lender
            </p>
          </a>
        </li>
      </ul>
    </div>
    <div className="card-body">
      <div className='row'>
        <div className='col-6 col-md-3'>
            <img src='assets/icon/tvs.webp' className='mx-auto d-block img-fluid'></img>
            <p className='text-center text-muted'>TVS Credit Services Limited</p>
        </div>
        <div className='col-6 col-md-3'>
            <img src='assets/icon/bajaj.webp' className='mx-auto d-block img-fluid'></img>
            <p className='text-center text-muted'>Bajaj Finance Limited</p>
        </div>
        <div className='col-6 col-md-3'>
            <img src='assets/icon/hdb.webp' className='mx-auto d-block img-fluid'></img>
            <p className='text-center text-muted'>HDB Financial Services</p>
        </div>
      </div>
    </div>
      </div>
      </div>
      </div>
      </div>
      
      </div>
  )
}

export default Loan_emi_payment