import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaWallet  } from "react-icons/fa";
import {
  favouriteCurrencies,
  saveOrderDetails,
  update_wallet_balance,
  taxationdata,
} from "./firebase";
import Select from "react-select";
import "sweetalert2/dist/sweetalert2.min.css";
import Swal from "sweetalert2";

const Nutewallet = () => {
  const { balance, phoneNumber } = useSelector((state) => state.user);
  const [amountInput, setAmountInput] = useState("");
  const [amountError, setAmountError] = useState("");
  const isAmountValid = !amountError && amountInput !== "" && !isNaN(amountInput);
  // const [data, setData] = useState(null);
  const [options, setfavTokens] = useState({
    "Virtual Digital Assets": [
      {
        // value: "",
        label: "Select Preferance",
        imageSrc: "https://nute.io/public/app/user.png",
      },
    ],
    "Indian Rupees": [
      {
        value: "Phone Pay",
        label: "Phone Pay",
        imageSrc: "/assets/icon/phonepay.webp",
      },
      { value: "GPay", label: "GPay", imageSrc: "/assets/icon/gpay.png" },
    ],
  });

  const [selectedCategory, setSelectedCategory] = useState(
    "Select Payment Method"
  );
  const [selectedItem, setSelectedItem] = useState("Select Preferance");

  const categoryOptions = Object.keys(options).map((category) => ({
    value: category,
    label: category.charAt(0).toUpperCase() + category.slice(1),
  }));

  const itemOptions = options[selectedCategory]
    ? options[selectedCategory].map((item, i) => ({
        value: item?.value
          ? item.value
          : item?.label
          ? item.label
          : item?.symbol
          ? item.symbol
          : item.nativeCurrency.symbol,
        label: (
          <div>
            <img
              src={
                item?.logo
                  ? item?.logo
                  : item?.imageSrc
                  ? item.imageSrc
                  : `https://raw.githubusercontent.com/Nute-Wallet/Chains/main/resources/${item.slug}/logo.png`
              }
              alt={item?.label ? item.label : item.symbol}
              style={{ width: "20px", marginRight: "5px" }}
            />
            {item?.label
              ? item.label
              : item?.symbol
              ? item.symbol
              : item.nativeCurrency.symbol}
          </div>
        ),
      }))
    : [];

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setSelectedItem("");
  };

  const handleCloseModal = () => {
    const myModal = new window.bootstrap.Modal(document.getElementById("AddMoney"));
    myModal.hide();
    
    const modalBackdrop = document.querySelector(".modal-backdrop");
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
  };
  

  const handleItemChange = (selectedOption) => {
    setSelectedItem(selectedOption.value);
  };

  const openModal = () => {
    if (!isAmountValid) {
      setAmountError("Please enter a valid number");
    } else {
      setAmountError("");
      const myModal = new window.bootstrap.Modal(
        document.getElementById("AddMoney")
      );
      myModal.show();
    }
  };
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmountInput(value);
    if (value && isNaN(value)) {
      setAmountError("Please enter a valid number");
    } else {
      setAmountError("");
    }
  };
  var tt = "";
  const submitForm = async () => {
    // const amountInputtag = document.getElementById("amountInput");
    const amount = Number(amountInput);
    if (!amountInput || isNaN(amountInput)) {
      setAmountError("Please enter a valid number");
      return;
    }

    // console.log("Amount:", amount);
    // console.log("Selected Item:", selectedItem);
    // console.log("Phone Number:", phoneNumber);

    setAmountInput("");
    setSelectedCategory("Select Payment Method");
    setSelectedItem("Select Preferance");

    try {
      // Fetching current price
      const response = await fetch(
        `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=${selectedItem}&api_key=b876fd47-5fe1-49fd-97df-6099bf162ffd`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const cr_price = data[selectedItem];
      const cr_price_decimal = parseFloat(cr_price).toFixed(8);

      const ttx = await taxationdata("1234");
      const tds = ttx.tds;
      const surcharge = ttx.surcharge;

      // console.log(tds, 'tds');
      // console.log(surcharge, 'surcharge');

      const total_amt =
        parseFloat(amount) +
        parseFloat(surcharge) +
        (parseFloat(amount) * parseFloat(tds)) / 100;
      const total_paid_amount = total_amt * parseFloat(cr_price_decimal);
      const amountt = total_paid_amount;
      // console.log(amountt, "amount");

      const amountInWei = Math.round(amountt * 1e18);

      const apiUrl = `https://link.merchant.nute.io/api/fetch-address?keys=128616df967df61f7139710c5ffbdd580ee689e046823504a3dd651f910f471b&symbol=${selectedItem}&amount=${amount}`;

      fetch(apiUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((decodedResponse) => {
          if (
            decodedResponse &&
            decodedResponse.data &&
            decodedResponse.data.address
          ) {
            const address = decodedResponse.data.address;
            const order_id = decodedResponse.data.order_id;
            // const response_url = decodedResponse.data.response_url;

            const ethereumUri = `ethereum:${address}?value=${amountInWei}`;
            const imageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
              ethereumUri
            )}`;
            // console.log(order_id, "order_id");
            var mobile_num =
              typeof phoneNumber == "object"
                ? phoneNumber?.phoneNumber
                : phoneNumber;
            // console.log(mobile_num, "mobile_num");
            Swal.fire({
              icon: "",
              title: "Scan And Add Money",
              html: `
                            <img src="${imageUrl}" style="width:200px;" class="mx-auto d-block">
                        `,
              showCloseButton: false,
              showConfirmButton: false,
            });

            tt = setInterval(() => {
              fetchData(order_id, (status, id) => {
                if (order_id === id) {
                  clearInterval(tt);
                  let data = {
                    address: address,
                    order_id: order_id,
                    phoneNumber: mobile_num,
                    amount: amount,
                    totalPaidAmount: total_paid_amount,
                    status: status,
                    receiveAmount: 0,
                    timestamp: new Date().getTime(),
                  };
                  saveOrderDetails(data);
                  update_wallet_balance(mobile_num,amount);
                  // console.log(mobile_num);
                  // console.log(amount);
                  Swal.fire({
                    icon: status == 1 ? "success" : "error",
                    title:
                      status == 1
                        ? "Success"
                        : "Payment failed",
                    html: `
                              <p>₹ <span style="font-size: 25px;font-weight: bold;">${amount}</span> is added Successfully! </p>
                            `,
                    showCloseButton: false,
                    showConfirmButton: false,
                  });
                  // window.location.reload();
                }
              });
            }, 10000);
            // store data

            // store data close
          }
        })
        .catch((error) => {
          clearInterval(tt);
          console.error("Error fetching data:", error);
        });
    } catch (error) {
      clearInterval(tt);
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    // if (options['Virtual Digital Assets']?.length == 0) {
    if (phoneNumber) {
      (async () => {
        var nn =
          typeof phoneNumber == "object"
            ? phoneNumber?.phoneNumber
            : phoneNumber;
        var tt = await favouriteCurrencies(nn);
        if (tt?.favTokens?.length > 0) {
          // console.log(tt?.favTokens, "tt?.favTokens");
          var opt = { ...options };
          opt["Virtual Digital Assets"] = tt?.favTokens;
          setfavTokens({ ...opt });
        }
      })();
    }
    // }
  }, [phoneNumber]);

  // useEffect(() => {
  async function fetchData(orderId, cb) {
    try {
      const response = await fetch("https://nute.io/api/cal_order_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({order_id:orderId}),
      });
      const json = await response.json();
      if (json && json.data) {
        cb(Number(json.data.status), json.data.order_id);
      }
    } catch (error) {
      clearInterval(tt);
      console.error("Error fetching data: ", error);
    }
  }

  // }, []);

 
  return (
    <div className="main_box">
      <div className="col-12 banner_box">
        <nav className="breadcrumb">
          <ul>
            <li>
              <a href="/recharge">Home</a>
            </li>
            <li className="breadcrumb_subtitle">
              <a href="#">Nute Wallet</a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="box2">
        <div className="sub_container">
          <div className="card light_box mb-5">
            <div
              className="card-header pt-4 pb-0 d-flex justify-content-between"
              style={{ background: "transparent" }}
            >
              <ul className="nav nav-pills">
                <li className="nav-item hov">
                  <a
                    className="nav-link active card_heading"
                    id="tab_wallet-tab"
                    data-bs-toggle="pill"
                    href="#tab_wallet"
                  >
                    <div className="d-flex ">
                      <span className="icon"><FaWallet /></span>
                      <p className="ps-3">
                        NUTE WALLET <br />
                        <span className="nute_bal">{balance}</span>
                      </p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body py-4">
              <div className="tab-content">
                <div className="tab-pane fade show active" id="tab_wallet">
                  <p
                    className="mb-3 ps-5"
                    style={{ fontWeight: 500, fontSize: "15px" }}
                  >
                    ADD MONEY TO WALLET
                  </p>
                  <div className="row bg-light p-5">
                    <div className="col-12 col-md-4">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="basic-addon3"
                            style={{ border: "none", background: "#fff" }}
                          >
                            ₹
                          </span>
                        </div>
                        <input
                          type="text"
                          className={`form-control ${
                            amountError ? "is-invalid" : ""
                          }`}
                          id="amountInput"
                          name="amount"
                          placeholder="Enter Amount"
                          aria-describedby="basic-addon3"
                          style={{ border: "none" }}
                          value={amountInput}
                          onChange={handleAmountChange}
                          onKeyDown={(event) =>
                            (event.ctrlKey ||
                              event.altKey ||
                              (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                              (95 < event.keyCode && event.keyCode < 106) ||
                              event.keyCode === 8 ||
                              event.keyCode === 9 ||
                              (event.keyCode > 34 && event.keyCode < 40) ||
                              event.keyCode === 46)
                              ? true
                              : event.preventDefault()
                          }
                        />
                        {amountError && (
                          <div className="invalid-feedback">{amountError}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <button
                        type="button"
                        className="btn d-block mx-auto w-100"
                        style={{ background: "#21bf73", color: "#fff" }}
                        data-bs-toggle="modal"
                        data-bs-target="#AddMoney"
                        id="addMoneyBtn"
                        onClick={openModal}
                        disabled={!isAmountValid}
                      >
                        Add Money
                      </button>
                    </div>
                  </div>
                  <p className="pt-4">
                    No Nute cash transactions associated with your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" id="AddMoney">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Money</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body rounded p-5" id="modalBody">
              <form id="get_qrcode">
                <Select
                  options={categoryOptions}
                  value={{ value: selectedCategory, label: selectedCategory }}
                  onChange={handleCategoryChange}
                />

                <br />

                <Select
                  options={itemOptions}
                  value={{ value: selectedItem, label: selectedItem }}
                  onChange={handleItemChange}
                  isDisabled={!selectedCategory}
                />

                <button
                  type="button"
                  onClick={submitForm}
                  className="btn d-block mx-auto w-100 mt-4 process"
                  style={{ background: "#21bf73", color: "#fff" }}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nutewallet;
