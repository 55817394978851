const circleOperators = [
  {
    circle_name: "DELHI (DL)",
    circle_code: "1",
    operator_id: [50, 51, 56,145],
  },
  {
    circle_name: "Maharashtra (MH)",
    circle_code: "4",
    operator_id: [72,76,110,114,117,287],
  },
  {
    circle_name: "Andhra Pradesh (AP)",
    circle_code: "5",
    operator_id: [65,66],
  },
  {
    circle_name: "TAMIL NADU (TN)",
    circle_code: "23",
    operator_id: [79],
  },
  {
    circle_name: "Karnataka (KA)",
    circle_code: "7",
    operator_id: [78,127,131,219,286],
  },
  {
    circle_name: "Gujarat (GJ)",
    circle_code: "8",
    operator_id: [53,57,58,59,128,130,170,172,233],
  },
  {
    circle_name: "UTTAR PRADESH East (UPE)",
    circle_code: "9",
    operator_id: [73,88,114,142,173],
  },
  {
    circle_name: "Madhya Pradesh (MP)",
    circle_code: "10",
    operator_id: [67,143,176,221,231],
  },
  {
    circle_name: "West Bengal (WB)",
    circle_code: "12",
    operator_id: [55,60,75,302],
  },
  {
    circle_name: "Rajasthan (RJ)",
    circle_code: "13",
    operator_id: [61,77,84,98,122,133],
  },
  {
    circle_name: "Kerala (KL)",
    circle_code: "14",
    operator_id: [],
  },
  {
    circle_name: "Punjab (PB)",
    circle_code: "15",
    operator_id: [118],
  },
  {
    circle_name: "Haryana (HR)",
    circle_code: "16",
    operator_id: [54,129,226],
  },
  {
    circle_name: "Bihar (BR)",
    circle_code: "17",
    operator_id: [63,64],
  },
  {
    circle_name: "ODISHA (OD)",
    circle_code: "18",
    operator_id: [116,119,135,218,304],
  },
  {
    circle_name: "Assam (AS)",
    circle_code: "19",
    operator_id: [70,134],
  },
  {
    circle_name: "Himachal Pradesh (HP)",
    circle_code: "21",
    operator_id: [125],
  },
  {
    circle_name: "Jammu And Kashmir (JK)",
    circle_code: "22",
    operator_id: [217],
  },
  {
    circle_name: "Jharkhand (JH)",
    circle_code: "24",
    operator_id: [123],
  },
  {
    circle_name: "CHHATTISGARH (CG)",
    circle_code: "25",
    operator_id: [83],
  },
  {
    circle_name: "GOA (GA)",
    circle_code: "26",
    operator_id: [],
  },
  {
    circle_name: "MEGHALAYA (ML)",
    circle_code: "28",
    operator_id: [121,227],
  },
  {
    circle_name: "MIZORAM (MZ)",
    circle_code: "29",
    operator_id: [],
  },
  {
    circle_name: "SIKKIM (SK)",
    circle_code: "31",
    operator_id: [174,225],
  },
  {
    circle_name: "TRIPURA (TR)",
    circle_code: "32",
    operator_id: [69],
  },
  {
    circle_name: "UTTAR PRADESH West (UPW)",
    circle_code: "42",
    operator_id: [],
  },
  {
    circle_name: "KOLKATTA (CCU)",
    circle_code: "31",
    operator_id: [],
  },
];
const operatorsImage = [
  {
    operator_id: 50,
    image: "/assets/favicon.png",
  },
];
export { circleOperators, operatorsImage };
