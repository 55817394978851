import React, { useState } from 'react';
import App_header from "./App_header";
import PropTypes from 'prop-types'; // Import prop-types
import Select from 'react-select';

const Education = () => {

  const [selectedOption, setSelectedOption] = useState({ value: 'Select Your Institute Location', label: 'Select Your Institute Location' });

  const options = [
    { value: 'Airtel', label: 'Airtel', image: 'assets/icon/airtel.webp' },
    { value: 'D2H', label: 'D2H', image: 'assets/icon/d2h.webp' },
    { value: 'TATA PLAY', label: 'TATA PLAY', image: 'assets/icon/tata_play.webp' },
    // Add more options as needed
  ];

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: '20px', margin: '8px' }}
      />
      {label}
    </div>
  );

  CustomOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    data: PropTypes.shape({
      image: PropTypes.string,
    }),
  };

  const handleChange = (selectedValue) => {
    setSelectedOption(selectedValue);
  };

  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
<div className='row'>
      <div className='col-12 col-md-4 box_new'>
      <div className='card light_box mb-5'>
      <div className='card-body'>
      <h6 className="mb-3">Pay Your Fee</h6>

      <form>

      <Select
        value={selectedOption}
        onChange={handleChange}
        options={options}
        components={{ Option: CustomOption }}
      />
        

      
        
        <button type="submit" className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed to Recharge</button>
        
      
    </form>

      </div>
      
      </div>

      </div>

      <div className='col-12 col-md-8 box_new mobile_box'>
      <div className='card light_box mb-5'>
      <div className="card-header pb-0" style={{ background: 'transparent' }}>
      <ul className="nav nav-pills" role="tablist">
        <li className="nav-item hov" role="presentation">
          <a 
            className="nav-link active card_heading" 
            id="tab_wallet-tab" 
            data-bs-toggle="pill" 
            href="#tab_wallet" 
            aria-selected="true" 
            role="tab"
          >
            <p className="ps-3">
              Promo Code <span style={{ color: '#20543b' }} id="current_opt"></span>
            </p>
          </a>
        </li>
      </ul>
    </div>
    <div className="card-body">
      <div className="tbl">
        <div className="operator_plans">
          <div>
            <div className="plan_header" id="plans-cont">
              {/* Content for plan header goes here */}
            </div>
          </div>
          <table className="table">
            <thead style={{ fontSize: '13px', color: '#999999' }}>
              <tr>
                <th width="100">Type</th>
                <th width="100">Validity</th>
                <th>Description</th>
                <th width="50">Price</th>
              </tr>
            </thead>
            {/* Table body can be added here */}
          </table>
          <div id="plans-list" className="plans_list">
            {/* List of plans goes here */}
          </div>
        </div>
      </div>
    </div>
      </div>
      </div>
      </div>
      </div>
      
      </div>
  )
}

export default Education