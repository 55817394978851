import React, { useState, useEffect } from 'react';
import App_header from "./App_header";
import Select from 'react-select';
import PropTypes from 'prop-types';

const Pay_lic_insurance = () => {
  const [selectedOption, setSelectedOption] = useState({ value: 'Insurer', label: 'Insurer' });
  const [ConsumerNumber, setConsumerNumber] = useState(null);
  const [amount, setAmount] = useState(null);
  const [operatorID, setOperatorID] = useState(null);
  const [data, setData] = useState(null); 
  const handleConsumerNumberChange = (e) => {
    setConsumerNumber(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: '30px', margin: '15px' }}
      />
      {label}
    </div>
  );

  CustomOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    data: PropTypes.shape({
      image: PropTypes.string,
    }),
  };

  const handleChangedropdown = (selectedValue) => {
    setSelectedOption(selectedValue);
    const selectedOperator = options.find(operator => operator.value === selectedValue.value);
    setOperatorID(selectedOperator ? selectedOperator.id : null);
    // Additional logic here if needed
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add logic to handle form submission
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://nute.io/api/dth/biller_list', {
          method: 'GET',
          headers: {
            'Cookie': 'ci_session=8o2rklmmeb88dqkrdg0vveova552lkbn'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    fetchData();
  }, []);



  const options = data && data.response
    .filter(operator => operator.service_type === 'Insurance' && operator.biller_status=== 'on')
    .map(operator => ({
      value: operator.operator_name,
      label: operator.operator_name,
      id: operator.operator_id,
      image: 
          operator.operator_name === 'AIRTEL DTH' ? 'assets/icon/airtel.webp'
        : operator.operator_name === 'Videocon D2h Official' ? 'assets/icon/videocon.png'
        :  'assets/icon/default.png',
    }));

  const defaultOptions = [{ value: 'Default', label: 'Default', image: 'assets/icon/default.png' }];

  console.log(operatorID, 'options', selectedOption);

  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 box_new' style={{marginLeft: "inherit"}}>
            <div className='card light_box mb-5'>
              <div className='card-body'>
                <h6 className="mb-3">Pay Insurance Premium</h6>
                <form onSubmit={handleSubmit}>
                  <div>
                    <Select
                      value={selectedOption}
                      onChange={handleChangedropdown}
                      options={options || defaultOptions}
                      components={{ Option: CustomOption }}
                      className='mt-4 drp_scroll'
                    />

                    <div className="form-floating ">
                      <input
                        type="text"
                        className={`form-control floating_input`}
                        id="ConsumerNumber"
                        placeholder="Consumer Number"
                        value={ConsumerNumber}
                        onChange={handleConsumerNumberChange}
                        onKeyDown={(event) =>
                          (event.ctrlKey ||
                            event.altKey ||
                            (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                            (95 < event.keyCode && event.keyCode < 106) ||
                            event.keyCode === 8 ||
                            event.keyCode === 9 ||
                            (event.keyCode > 34 && event.keyCode < 40) ||
                            event.keyCode === 46)
                            ? true
                            : event.preventDefault()
                        }
                      />
                      <label htmlFor="ConsumerNumber">Policy Number</label>
                    </div>

                    <div className="form-floating ">
                      <input
                        type="text"
                        className={`form-control floating_input`}
                        id="prepaidAmount"
                        placeholder="Amount"
                        value={amount}
                        onChange={handleAmountChange}
                        onKeyDown={(event) =>
                          (event.ctrlKey ||
                            event.altKey ||
                            (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                            (95 < event.keyCode && event.keyCode < 106) ||
                            event.keyCode === 8 ||
                            event.keyCode === 9 ||
                            (event.keyCode > 34 && event.keyCode < 40) ||
                            event.keyCode === 46)
                            ? true
                            : event.preventDefault()
                        }
                      />
                      <label htmlFor="prepaidAmount">Date of Birth</label>
                    </div>

                    <button type="submit" className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed to Pay Electricity Bill</button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          

        </div>

        <div className='row'>
        <div className='col-12 col-md-12'>
            <div className='card light_box mb-5'>
              <div className="card-header pb-0" style={{ background: 'transparent' }}>
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item hov" role="presentation">
                    <a
                      className="nav-link active card_heading"
                      id="tab_wallet-tab"
                      data-bs-toggle="pill"
                      href="#tab_wallet"
                      aria-selected="true"
                      role="tab"
                    >
                      <p className="ps-3">Insurer</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
              <div className='row'>
              {options && options
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map(operator => (
                    <div className='col-6 col-md-2' key={operator.value}>
                      
                      
                      
                      <p
                        className={`operator_hov text-center ${operator.value === selectedOption.value ? 'active' : ''}`}
                        onClick={() => handleChangedropdown(operator)} 
                        style={{cursor: 'pointer'}}
                      >
                        <img src={operator.image} style={{width:'85px'}} className='mx-auto d-block'/>
                        {operator.label}
                      </p>
                    </div>
                    
                   
                  ))}
              </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pay_lic_insurance;
