import React, { useState, useEffect } from 'react';
import App_header from "./App_header";
import Select from 'react-select';
import PropTypes from 'prop-types';

const Electricity_bill_payment = () => {
  const [selectedValue, setSelectedValue] = useState('Electricity Boards');
  const [showStateDropdown, setShowStateDropdown] = useState(selectedValue === 'Electricity Boards');
  const [selectedstateOption, setSelectedstateOption] = useState({ value: 'state', label: 'State' });
  const [selectedOption, setSelectedOption] = useState({ value: 'Electricity Board', label: 'Electricity Board' });
  const [ConsumerNumber, setConsumerNumber] = useState(null);
  const [amount, setAmount] = useState(null);
  const [showCityDropdown, setShowCityDropdown] = useState(selectedValue === 'Apartments');
  const [selectedOptionCity, setSelectedOptionCity] = useState({ value: 'City', label: 'City' });
  const [operatorID, setOperatorID] = useState(null);
  const [statedata, setstateData] = useState(null); 
  const [selectedStateFromList, setSelectedStateFromList] = useState(null);
  const [data, setData] = useState(null); 
  const handleConsumerNumberChange = (e) => {
    setConsumerNumber(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: '30px', margin: '15px' }}
      />
      {label}
    </div>
  );

  CustomOption.propTypes = {
    innerProps: PropTypes.object,
    label: PropTypes.string,
    data: PropTypes.shape({
      image: PropTypes.string,
    }),
  };

  const handleChangedropdown = (selectedValue) => {
    setSelectedOption(selectedValue);
    const selectedOperator = options.find(operator => operator.value === selectedValue.value);
    setOperatorID(selectedOperator ? selectedOperator.id : null);
  };

  const handleChangestate = (selectedValue) => {
    setSelectedstateOption(selectedValue);
  };

  const handleChangecity = (selectedValue) => {
    setSelectedOptionCity(selectedValue);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);

    setShowStateDropdown(newValue === 'Electricity Boards');
    setShowCityDropdown(newValue === 'Apartments');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://nute.io/api/dth/circle', {
          method: 'GET',
          headers: {
            'Cookie': 'ci_session=8o2rklmmeb88dqkrdg0vveova552lkbn'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        
        setstateData(result);
      } catch (error) {
        console.error('Error fetching state data:', error);
      } 
    };

    fetchData();
  }, []);

  const optionsState = statedata && statedata.response.map(circle => ({
      value: circle.circle_name.replace(/\s+\([^)]+\)$/, ''), 
        label: circle.circle_name.replace(/\s+\([^)]+\)$/, ''), 
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://nute.io/api/dth/biller_list', {
          method: 'GET',
          headers: {
            'Cookie': 'ci_session=8o2rklmmeb88dqkrdg0vveova552lkbn'
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();

        setData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };

    fetchData();
  }, []);
  var ss = selectedstateOption?.operators?.operator_id;
  const options = data && data.response
    .filter(operator => operator.service_type === 'ELC' && operator.biller_status=== 'on')
    .map(operator => ({
      value: operator.operator_name,
      label: operator.operator_name,
      id: operator.operator_id,
      image: 
          operator.operator_name === 'BSES Yamuna Power Limited - Delhi' ? 'assets/elc/bses.webp'
        : operator.operator_name === 'BSES Rajdhani Power Limited - Delhi' ? 'assets/elc/bses.webp'
        :  'assets/icon/default.png',
    }));

    
  const defaultOptions = [{ value: 'Default', label: 'Default', image: 'assets/icon/default.png' }];
console.log(operatorID,'options',ss);

const handleStateClick = (state) => {
  setSelectedStateFromList(state); 
  setSelectedstateOption({ value: state, label: state }); 
};
  return (
    <div className='container-fluid'>
      <App_header />
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-4 box_new'>
            <div className='card light_box mb-5'>
              <div className='card-body'>
              {selectedValue === 'Electricity Boards' && (
                <h6 className="mb-3">Pay Electricity Bill</h6>
                )}
                {selectedValue === 'Apartments' && (
                  <h6 className="mb-3">Pay Apartment Bill</h6>
                )}
                <form onSubmit={handleSubmit}>
                  <label className='me-3'>
                    <input
                      className='me-1'
                      type="radio"
                      value="Electricity Boards"
                      checked={selectedValue === 'Electricity Boards'}
                      onChange={handleChange}
                    />
                    Electricity Boards
                  </label>
                  <label>
                    <input
                      className='me-1'
                      type="radio"
                      value="Apartments"
                      checked={selectedValue === 'Apartments'}
                      onChange={handleChange}
                    />
                    Apartments
                  </label>

                  {showStateDropdown && (
  <div>
    <Select
                        value={selectedstateOption}
                        onChange={handleChangestate}
                        options={optionsState || []}
                        className='my-3'
                      />

                  <Select
                    value={selectedOption}
                    onChange={handleChangedropdown}
                    options={options || defaultOptions}
                    components={{ Option: CustomOption }}
                    className='mt-4 drp_scroll'
                  />

                  <div className="form-floating ">
                    <input
                      type="text"
                      className={`form-control floating_input`}
                      id="ConsumerNumber"
                      placeholder="Consumer Number"
                      value={ConsumerNumber}
                      onChange={handleConsumerNumberChange}
                      onKeyDown={(event) =>
                        (event.ctrlKey ||
                          event.altKey ||
                          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                          (95 < event.keyCode && event.keyCode < 106) ||
                          event.keyCode === 8 ||
                          event.keyCode === 9 ||
                          (event.keyCode > 34 && event.keyCode < 40) ||
                          event.keyCode === 46)
                          ? true
                          : event.preventDefault()
                      }
                    />
                    <label htmlFor="ConsumerNumber">Consumer Number</label>
                  </div>

                  <div className="form-floating ">
                    <input
                      type="text"
                      className={`form-control floating_input`}
                      id="prepaidAmount"
                      placeholder="Amount"
                      value={amount}
                      onChange={handleAmountChange}
                      onKeyDown={(event) =>
                        (event.ctrlKey ||
                          event.altKey ||
                          (47 < event.keyCode && event.keyCode < 58 && !event.shiftKey) ||
                          (95 < event.keyCode && event.keyCode < 106) ||
                          event.keyCode === 8 ||
                          event.keyCode === 9 ||
                          (event.keyCode > 34 && event.keyCode < 40) ||
                          event.keyCode === 46)
                          ? true
                          : event.preventDefault()
                      }
                    />
                    <label htmlFor="prepaidAmount">Amount</label>
                  </div>
    

    <button type="submit" className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed to Pay Electricity Bill</button>
  </div>
)}

{showCityDropdown && (
  <div>
    <Select
      value={selectedOptionCity}
      onChange={handleChangecity}
      options={options}
      className='my-3'
    />
    <button type="submit" className="btn btn_green text-white mt-3 mx-auto process d-block px-3">Proceed Apartments Bill</button>
  </div>
)}

                  
                </form>
              </div>
            </div>
          </div>

          {selectedValue === 'Electricity Boards' && (
            <div className='col-12 col-md-8 box_new mobile_box'>
              <div className='card light_box mb-5'>
                <div className="card-header pb-0" style={{ background: 'transparent' }}>
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item hov" role="presentation">
                      <a
                        className="nav-link active card_heading"
                        id="tab_wallet-tab"
                        data-bs-toggle="pill"
                        href="#tab_wallet"
                        aria-selected="true"
                        role="tab"
                      >
                        <p className="ps-3">State</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                
                
                {optionsState && optionsState
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map(state => (
                      <span
                        className={`state_bg ${state.value === selectedStateFromList ? 'active' : ''}`}
                        key={state.value}
                        onClick={() => handleStateClick(state.value)} // Handle state click
                      >
                        {state.label}
                      </span>
                  ))}
               

                </div>
              </div>
            </div>
          )}

          {selectedValue === 'Apartments' && (
            <div className='col-12 col-md-8 box_new mobile_box'>
              <div className='card light_box mb-5'>
                <div className="card-header pb-0" style={{ background: 'transparent' }}>
                  <ul className="nav nav-pills" role="tablist">
                    <li className="nav-item hov" role="presentation">
                      <a
                        className="nav-link active card_heading"
                        id="tab_wallet-tab"
                        data-bs-toggle="pill"
                        href="#tab_wallet"
                        aria-selected="true"
                        role="tab"
                      >
                        <p className="ps-3">City</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="card-body">
                  <ul>
                    <li>City1</li>
                    <li>City2</li>
                    <li>City3</li>
                  </ul>
                </div>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
}

export default Electricity_bill_payment;
