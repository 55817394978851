import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./Navbar";
import Home from "./Home";
import Recharge from "./Recharge";
import Nutewallet from "./Nutewallet";
import Electricity_bill_payment from "./Electricity_bill_payment";
import DTH from "./DTH";
import Metro_card_recharge from "./Metro_card_recharge";
import Broadband_landline from "./Broadband_landline";
import Loan_emi_payment from "./Loan_emi_payment";
import Fastag from "./Fastag";
import Education from "./Education";
import Pay_lic_insurance from "./Pay_lic_insurance";
import Water_bill_payment from "./Water_bill_payment";
import Test from "./Test";
import ErrorPage from "./ErrorPage";
import "./css/style.css";

function App() {
  const [islogin, setislogin] = useState(true);
  useEffect(() => {
    const storedData = localStorage.getItem('userData');

    if (storedData) {
      // console.log('Success');
      setislogin(true);
    } else {
      setislogin(false);
      // window.location.href = 'http://localhost:3000/';
    }
  }, []);
  return (
      <div className="App">
        <BrowserRouter>
          <Navbar />
          
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recharge" element={islogin?<Recharge />:<Navigate to="/"/>} />
            <Route path="/nutewallet" element={islogin?<Nutewallet />:<Navigate to="/"/>} />
            <Route path="/electricity_bill_payment" element={islogin?<Electricity_bill_payment />:<Navigate to="/"/>} />
            <Route path="/dth_recharge" element={islogin?<DTH />:<Navigate to="/"/>} />
            <Route path="/metro_card_recharge" element={islogin?<Metro_card_recharge />:<Navigate to="/"/>} />
            <Route path="/broadband_landline" element={islogin?<Broadband_landline />:<Navigate to="/"/>} />
            <Route path="/loan_emi_payment" element={islogin?<Loan_emi_payment />:<Navigate to="/"/>} />
            <Route path="/fastag" element={islogin?<Fastag />:<Navigate to="/"/>} />
            <Route path="/education" element={islogin?<Education />:<Navigate to="/"/>} />
            <Route path="/pay_lic_insurance" element={islogin?<Pay_lic_insurance />:<Navigate to="/"/>} />
            <Route path="/water_bill_payment" element={islogin?<Water_bill_payment />:<Navigate to="/"/>} />
            <Route path="/test" element={<Test />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
